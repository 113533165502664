.dots hr.top {
    margin-bottom: 0;
}

.dots hr {
    margin-top: 0;
    margin-bottom: 0;
}

.dots .app-store-links,
.dots .watch-demo-button {
    margin-bottom: 30px;
}

.dots .green {
    color: #228B22;
    font-weight: bold;
}

.dots .blue {
    color: #1E90FF;
    font-weight: bold;
}

.dots .purple {
    color: #9932CC;
    font-weight: bold;
}

.dots .orange {
    color: #FF8C00;
    font-weight: bold;
}

.dots .red {
    color: #F84946;
    font-weight: bold;
}
