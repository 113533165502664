.words hr.top {
    margin-bottom: 0;
}

.words hr {
    margin-top: 0;
    margin-bottom: 0;
}

.words .app-store-links,
.words .watch-demo-button {
    margin-bottom: 30px;
}

.words .red {
    color: #FF1111;
    font-weight: bold;
}

.words .yellow {
    color: #BBBB11;
    font-weight: bold;
}

.words .green {
    color: #11AA11;
    font-weight: bold;
}
