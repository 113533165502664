.schedule .action {
    margin-top: 26px;
}

.schedule hr {
    margin-top: 40px;
    margin-bottom: 30px;
}

.schedule .point-of-interest .description {
    text-align: center;
}
