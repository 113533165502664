.privacy {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}

.privacy ul.i {
    list-style-type: disc !important;
    margin-bottom: 16px;
}

.privacy ul.ii {
    list-style-type: circle !important;
}

.privacy ul.iii {
    list-style-type: square !important;
}

.privacy li {
    list-style-type: inherit !important;
    display: list-item;
}
