.home .card {
    max-width: 300px;
    min-width: 200px;
    margin: 30px;
}

.home .icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(180deg, #5FAFDC 0%, #0F52BA 100%);
}

.home .billing {
    margin-top: 10px;
}

.home .MuiListItemText-primary {
    font-weight: 500;
}

.home .lighter .MuiListItemText-primary {
    font-weight: 400;
}

.home .follow-us {
    margin-top: 12px;
}
