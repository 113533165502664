.screenshots.cards {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 -20px;
    padding-left: 10px;
    padding-right: 10px;
}

.wide .screenshots.cards {
    justify-content: center;
}

.screenshots .card {
    max-width: 300px;
    margin: 30px 10px;
}

.wide .screenshots .card {
    margin: 30px;
}

.screenshots img.screenshot {
    width: 300px;
}
