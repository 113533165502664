.points-of-interest {
    margin: 30px auto;
    padding-top: 15px;
    padding-bottom: 15px;
}

.wide .points-of-interest {
    padding-top: 0;
    padding-bottom: 0;
}

.point-of-interest {
    max-width: 300px;
    margin: 15px 30px;
}

.wide .point-of-interest {
    max-width: 300px;
    margin: 30px;
}

.point-of-interest h5 {
    margin-bottom: 4px;
}

.point-of-interest .description {
    text-align: left;
}
