.aup {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}

.aup ul {
    list-style: disc !important;
    margin-bottom: 16px;
}

.aup li {
    list-style-type: inherit !important;
    display: list-item;
}
