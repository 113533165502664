.header {
    height: 56px;
    top: 0;
    bottom: auto;
}

.header .items .left {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
}

.header .items .right {
    width: 25%;
    display: flex;
    flex-wrap: wrap;
}

.wide .header .items .left,
.wide .header .items .right {
    width: 50%;
}

.header .items .left {
    justify-content: left;
}

.header .items .right {
    justify-content: right;
}

.header .item {
    margin-left: 16px;
    line-height: 42px;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wide .header .item {
    margin-left: 30px;
}

.header .left .item.no-click {
    cursor: inherit;
}

.header .left .item.first {
    margin-left: 0;
}

.header .right .item.first {
    margin-left: auto;
}

.header .item a {
    text-decoration: none;
}

.header .company-logo img {
    height: 52px;
}
