.reveal hr.top {
    margin-bottom: 0;
}

.reveal hr {
    margin-top: 0;
    margin-bottom: 0;
}

.reveal .app-store-links,
.reveal .watch-demo-button {
    margin-bottom: 30px;
}

.reveal .green {
    color: #228B22;
    font-weight: bold;
}

.reveal .blue {
    color: #1E90FF;
    font-weight: bold;
}

.reveal .purple {
    color: #9932CC;
    font-weight: bold;
}

.reveal .orange {
    color: #FF8C00;
    font-weight: bold;
}

.reveal .red {
    color: #F84946;
    font-weight: bold;
}
